<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">{{ lista_campos != '' ? 'Editar' : 'Criar' }} empresa</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="confirm(lista_campos === '' ? 'cria' : 'edita')">
            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">Nome Fantasia:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.nome_fantasia"
                    placeholder="Digite o nome..."
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Razão social:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.razao_social"
                    placeholder="Digite o nome..."
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">CNPJ:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.cnpj"
                    placeholder="Digite o cnpj..."
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-8">
                <label class="col-md-12">Rua:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.rua"
                    placeholder="Digite a rua..."
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">CEP:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.cep"
                    placeholder="Digite o cep..."
                />
              </div>
              <div class="col-md-8">
                <label class="col-md-12">Bairro:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.bairro"
                    placeholder="Digite o bairro..."
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">Email:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.email"
                    placeholder="Digite o email..."
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Telefone 1:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.telefone1"
                    placeholder="Digite o telefone..."
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Telefone2:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.telefone2"
                    placeholder="Digite o telefone2..."
                />
              </div>
            </div>

            <div class="form-group row d-flex align-items-end">

           
              <div class="col-md-3">
                <label class="col-md-12">Contato do Vendedor:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.contato_vendedor"
                />
              </div>

              <div class="col-md-3">
                <label class="col-md-12">Ramal:*</label>
                <input
                    
                    type="text"
                    class="form-control"
                    v-model="form.ramal"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-primary" :disabled="verif">
                  Salvar
                  <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {fireAlert} from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        razao_social: "",
        nome_fantasia: "",
        rua: "",
        bairro: "",
        cep: "",
        telefone1: "",
        cidade_id: 1,
        email: "",
        telefone2: "",
        contato_vendedor: "",
        ramal: "",
        filial_id: 1,
        user_in: "admin",
        user_up: "admin",
        status: 1,
      },
      verif: false,
    };
  },

  created() {
    this.preenxerCampos();
    this.listar_requerimentos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
    lista_finan_modo_pagamentos() {
      return this.$store.state.financeiro.lista_finan_modo_pagamentos;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` um fornecedor no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo === "cria") this.create();
            if (tipo === "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("financeiro/create_fornecedor", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'fornecedor'
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("financeiro/update_fornecedor", this.form);
      this.verif = false;
      console.log(this.mensagem_alert.tipo, this.mensagem_alert.message)
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'fornecedor'
      });
    },
    preenxerCampos() {
      console.log(this.form, this.lista_campos)
      if (this.lista_campos !== "")
        Object.assign(this.form, this.lista_campos)
    },
    listar_requerimentos() {
      this.$store.dispatch("financeiro/listar_finan_modo_pagamento");
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>